import '../scss/main.scss';

"use-strict"
let jQuery = require('jquery');

window.J = jQuery;
window.Jd = J(document);

Jd.ready(function () {

    function createCookie(name, value, days) {
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            var expires = "; expires=" + date.toGMTString()
        } else var expires = "";
        document.cookie = name + "=" + value + expires + "; path=/"
    }

    function readCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
        }
        return null
    }

    function eraseCookie(name) {
        createCookie(name, "", -1)
    }

    setTimeout(function () {
        createCookie("popup-bon", true, 30);
    }, 3000);

    if (readCookie("popup-bon")) {
        $('.popup-bon').removeClass('active');
    } else {
        $('.popup-bon').addClass('active');
    }


    $('.close-popup').click(function () {
        $('.popup-bon').removeClass('active');
        return false;
    });

    $('.open-accordion').click(function () {
        $(this).hide();
        $('.accordions .text').show();
        return false;
    });

    $('.double_column > .container .wrapper_cols .col').each(function (i, el) {
        $(el).find('.open-testimonials').click(function () {
            $(el).find('.open-testimonials').hide();
            $(el).find('.temoignage_others').show();
            return false;
        });
    });

    $('.open-accordion-timeline').click(function () {
        $(this).hide();
        $('.dates_cles_timeline > .timeline_detail').show();
        return false;
    });

    $('.open-author-left').click(function () {
        $('aside.popup_more#textes_more_1').addClass('active');
        return false;
    });

    $('.open-author-right').click(function () {
        $('aside.popup_more#textes_more_2').addClass('active');
        return false;
    });

    $('.open-popup-partner-word').click(function () {
        $('aside.popup_partner_word').removeClass('active');
        $(this).parent().parent().find('.popup_partner_word').addClass('active');
        return false;
    });

    $('.close-popup-partner-word').click(function () {
        $('aside.popup_partner_word').removeClass('active');
        return false;
    });

    $('.open_popin_share_friend_form').click(function () {
        $('aside.popin_share_friend_form').addClass('active');
        return false;
    });

    $('.close_popin_share_friend_form').click(function () {
        $('aside.popin_share_friend_form').removeClass('active');
        return false;
    });

    $(document).on("click", function (event) {
        // If the target is not the container or a child of the container, then process
        // the click event for outside of the container.
        if ($(event.target).closest("aside.popup_more#textes_more_1 > .container, aside.popup_more#textes_more_2 > .container").length === 0) {
            $('aside.popup_more').removeClass('active');
        }

        if ($(event.target).closest("aside.popup_partner_word, aside.popin_share_friend_form").length === 0) {
            $('aside.popup_partner_word').removeClass('active');
        }
    });


    // slider-home
    $('.slider-home').owlCarousel({
        items: 1,
        loop: true,
        nav: true,
        dots: true,
        autoplay: true,
        autoplaySpeed: 2000,
        autoplayTimeout: 7000,
        autoplayHoverPause: true,
        navText: ["<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"15.743\" height=\"25.996\" viewBox=\"0 0 15.743 25.996\">\n" +
        "    <defs>\n" +
        "        <style>.a{fill:#fff;}</style>\n" +
        "    </defs>\n" +
        "    <path class=\"a\" d=\"M1285.008,732.008l2.74,2.74-10.26,10.258,10.26,10.258-2.74,2.741-13-13Z\"\n" +
        "          transform=\"translate(-1272.005 -732.008)\"/>\n" +
        "</svg>", "<svg width=\"16px\" height=\"26px\" viewBox=\"0 0 16 26\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\">\n" +
        "    <title>arrow-right</title>\n" +
        "    <g id=\"Page-1\" stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\">\n" +
        "        <g id=\"arrow-right\" transform=\"translate(7.873000, 12.998500) rotate(180.000000) translate(-7.873000, -12.998500) translate(0.003000, 0.000000)\" fill=\"#FFFFFF\" fill-rule=\"nonzero\">\n" +
        "            <polygon id=\"Path\" points=\"13 0 15.74 2.74 5.48 12.998 15.74 23.256 13 25.997 0 12.997\"></polygon>\n" +
        "        </g>\n" +
        "    </g>\n" +
        "</svg>"]
    });

    // testimonials
    $('.testimonials').owlCarousel({
        items: 1,
        loop: true,
        margin: 50,
        nav: false,
        dots: true,
        autoplay: false,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
    });

    // slider-testimonials
    $('.slider-testimonials').owlCarousel({
        items: 1,
        loop: true,
        nav: true,
        dots: true,
        autoplay: true,
        autoplaySpeed: 2000,
        autoplayTimeout: 7000,
        autoplayHoverPause: true,
        navText: ["<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"15.743\" height=\"25.996\" viewBox=\"0 0 15.743 25.996\">\n" +
        "    <defs>\n" +
        "        <style>.a{fill:#fff;}</style>\n" +
        "    </defs>\n" +
        "    <path class=\"a\" d=\"M1285.008,732.008l2.74,2.74-10.26,10.258,10.26,10.258-2.74,2.741-13-13Z\"\n" +
        "          transform=\"translate(-1272.005 -732.008)\"/>\n" +
        "</svg>", "<svg width=\"16px\" height=\"26px\" viewBox=\"0 0 16 26\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\">\n" +
        "    <title>arrow-right</title>\n" +
        "    <g id=\"Page-1\" stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\">\n" +
        "        <g id=\"arrow-right\" transform=\"translate(7.873000, 12.998500) rotate(180.000000) translate(-7.873000, -12.998500) translate(0.003000, 0.000000)\" fill=\"#FFFFFF\" fill-rule=\"nonzero\">\n" +
        "            <polygon id=\"Path\" points=\"13 0 15.74 2.74 5.48 12.998 15.74 23.256 13 25.997 0 12.997\"></polygon>\n" +
        "        </g>\n" +
        "    </g>\n" +
        "</svg>"]
    });

    // slider-partners
    $('.slider-partners').owlCarousel({
        items: 1,
        loop: true,
        center: true,
        margin: 50,
        nav: false,
        dots: false,
        autoWidth: true,
        autoplay: true,
        autoplaySpeed: 500,
        autoplayTimeout: 2000,
        autoplayHoverPause: true,
        navText: [],
        responsive: {
            // breakpoint from 0 up
            768: {
                items: 3,
                autoWidth: false,
            },
            // breakpoint from 480 up
            1080: {
                items: 5,
                autoWidth: false,
            }

        }
    });

    // slider-presse
    $('#slider_presse').owlCarousel({
        items: 1,
        loop: true,
        nav: true,
        dots: true,
        autoplay: true,
        autoplaySpeed: 2000,
        autoplayTimeout: 2000,
        autoplayHoverPause: true,
        navText: ["<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"15.743\" height=\"25.996\" viewBox=\"0 0 15.743 25.996\">\n" +
        "    <defs>\n" +
        "        <style>.a{fill:#fff;}</style>\n" +
        "    </defs>\n" +
        "    <path class=\"a\" d=\"M1285.008,732.008l2.74,2.74-10.26,10.258,10.26,10.258-2.74,2.741-13-13Z\"\n" +
        "          transform=\"translate(-1272.005 -732.008)\"/>\n" +
        "</svg>", "<svg width=\"16px\" height=\"26px\" viewBox=\"0 0 16 26\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\">\n" +
        "    <title>arrow-right</title>\n" +
        "    <g id=\"Page-1\" stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\">\n" +
        "        <g id=\"arrow-right\" transform=\"translate(7.873000, 12.998500) rotate(180.000000) translate(-7.873000, -12.998500) translate(0.003000, 0.000000)\" fill=\"#FFFFFF\" fill-rule=\"nonzero\">\n" +
        "            <polygon id=\"Path\" points=\"13 0 15.74 2.74 5.48 12.998 15.74 23.256 13 25.997 0 12.997\"></polygon>\n" +
        "        </g>\n" +
        "    </g>\n" +
        "</svg>"]
    });

    // slider-partenaires
    $('#slider_partners_press').owlCarousel({
        items: 1,
        margin: 20,
        loop: true,
        nav: true,
        dots: true,
        autoplay: true,
        autoplaySpeed: 2000,
        autoplayTimeout: 7000,
        autoplayHoverPause: true,
        navText: ["<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"15.743\" height=\"25.996\" viewBox=\"0 0 15.743 25.996\">\n" +
        "    <defs>\n" +
        "        <style>.a{fill:#fff;}</style>\n" +
        "    </defs>\n" +
        "    <path class=\"a\" d=\"M1285.008,732.008l2.74,2.74-10.26,10.258,10.26,10.258-2.74,2.741-13-13Z\"\n" +
        "          transform=\"translate(-1272.005 -732.008)\"/>\n" +
        "</svg>", "<svg width=\"16px\" height=\"26px\" viewBox=\"0 0 16 26\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\">\n" +
        "    <title>arrow-right</title>\n" +
        "    <g id=\"Page-1\" stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\">\n" +
        "        <g id=\"arrow-right\" transform=\"translate(7.873000, 12.998500) rotate(180.000000) translate(-7.873000, -12.998500) translate(0.003000, 0.000000)\" fill=\"#FFFFFF\" fill-rule=\"nonzero\">\n" +
        "            <polygon id=\"Path\" points=\"13 0 15.74 2.74 5.48 12.998 15.74 23.256 13 25.997 0 12.997\"></polygon>\n" +
        "        </g>\n" +
        "    </g>\n" +
        "</svg>"]
    });

    var wow = new WOW(
        {
            boxClass: 'wow',      // animated element css class (default is wow)
            animateClass: 'animated', // animation css class (default is animated)
            offset: 0,          // distance to the element when triggering the animation (default is 0)
            mobile: true,       // trigger animations on mobile devices (default is true)
            live: true,       // act on asynchronously loaded content (default is true)
            callback: function (box) {
                // the callback is fired every time an animation is started
                // the argument that is passed in is the DOM node being animated
            },
            scrollContainer: null // optional scroll container selector, otherwise use window
        }
    );
    wow.init();

    // smooth scroll go top
    $(document).ready(function () {
        $('.js-scrollTo').on('click', function () { // Au clic sur un élément
            var page = $(this).attr('href'); // Page cible
            var speed = 750; // Durée de l'animation (en ms)
            $('html, body').animate({scrollTop: $(page).offset().top}, speed); // Go
            return false;
        });
    });

    // LOGIN MEMBRE
    $(document).ready(function () {
        $("#espace_login").click(function () {
            $("#espace_form").toggleClass("active");
        });

        $(".lien_no_click > a").removeAttr("href");

        const body = document.body,
            burger = document.querySelector('.wrapper--burger'),
            buttonBurger = document.querySelector('button.burger--button'),
            burgerMenu = document.querySelector('aside.burger-menu'),
            itemNavWithChildren = document.querySelectorAll('aside.burger-menu > .container ul.menu > li.menu-item.menu-item-has-children > a');

        //Nav
        burger.addEventListener('click', event => {
            event.preventDefault();
            body.classList.toggle('menu-open');
            buttonBurger.classList.toggle('active');
            burgerMenu.classList.toggle('active');
        });

        //Nav - submenu
        if (window.matchMedia("(max-width: 1080px)").matches) {
            itemNavWithChildren.forEach(function (element, index) {
                element.addEventListener('click', function (event) {
                    event.preventDefault();
                    element.parentElement.classList.toggle('active');
                });
            });
        }

        //Open Popin Video
        const buttonOpenPopinVideo = document.querySelector('.open_popin_video'),
            buttonClosePopinVideo = document.querySelector('.close_popin_video'),
            video = document.querySelector('.popin_video iframe'),
            popinVideo = document.querySelector('.popin_video');

        let times = 0, playY;

        if (popinVideo) {
            buttonOpenPopinVideo.addEventListener('click', event => {
                event.preventDefault();
                body.classList.add('popin-open');
                popinVideo.classList.add('active');
                if (times === 0) {
                    playY = video.src += '&autoplay=1';
                    times = 1;
                }
            });

            buttonClosePopinVideo.addEventListener('click', event => {
                event.preventDefault();
                body.classList.remove('popin-open');
                popinVideo.classList.remove('active');
                playY = playY.slice(0, -11);
                video.src = playY
                times = 0;
            });
        }
    });
});